import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'button-export',
	templateUrl: './button-export.component.html',
	styleUrls: ['./button-export.component.scss'],
})
export class ButtonExportComponent{
	@Output() eventClick = new EventEmitter<any>();

	export() {
		this.eventClick.emit();
	}
}
