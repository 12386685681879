import { Injectable } from '@angular/core';
import { RestCallService } from '../../../../core/services/rest-call/rest-call.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { IEnvironments } from '../Interfaces/IEnvironments';
import { TranslateApplicationService } from '../../../../core/translate/translate-application-service';
import { CognitoService } from 'src/app/core/services/cognito/cognito.service';
@Injectable({
	providedIn: 'root',
})
export class EnvironmentsService extends RestCallService {
	public dataToSend: IEnvironments = {
		pk_environment: 0,
		environment: '',
		description: '',
		normalemails: false,
        notnormalesmails: '',
        active: false,
        username: ''
	};

	constructor(
		public httpClient: HttpClient,
		public toastr: ToastrService,
		public notificationService: NotificationService,
		public translate: TranslateApplicationService, 
		private cognitoService: CognitoService
	) {
		super(httpClient, 'APIMedicApp', toastr, notificationService, translate);
	}

  public GetActiveEnvironment() {

		return this.get<IEnvironments[]>(
			'/api/Environments/GetAvailableEnvironments',
		  
			  );

    
		
	}
}