import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CognitoService } from 'src/app/core/services/cognito/cognito.service';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { RestCallService } from 'src/app/core/services/rest-call/rest-call.service';
import { TranslateApplicationService } from 'src/app/core/translate/translate-application-service';
import { ISettings } from '../Interfaces/ISettings';
import { ILastReleases } from '../Interfaces/ILastReleases';

@Injectable({
  providedIn: 'root',
})
export class SettingsService extends RestCallService {
  public dataToSend: ISettings = {
    pksetting: 0,
    settingname: '',
    settingvalue: '',
    settingorder: 0,
    available: false,
    fkuserupdater: 0,
    lastupdated: new Date
  }
  
  constructor(
		public httpClient: HttpClient,
		private cognito: CognitoService,
		public toastr: ToastrService,
		public notificationService: NotificationService,
		public translate: TranslateApplicationService,
	) {
		super(
			httpClient,
			'APIMedicApp',
			toastr,
			notificationService,
			translate,
		);
  }
  
  public GetProperty(data: any) {
    
    this.dataToSend.settingname = data;

		return this.post<ISettings[]>('/api/Settings/GetProperty', this.dataToSend, );
  }

  public GetLastReleases(){
    return this.post<ILastReleases[]>('/api/Settings/GetLastReleases', );
  }
}