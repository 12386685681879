import { NgModule } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { ColorPickerModule } from 'primeng/colorpicker';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputNumberModule } from 'primeng/inputnumber';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ListboxModule } from 'primeng/listbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { PasswordModule } from 'primeng/password';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { SliderModule } from 'primeng/slider';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { AccordionModule } from 'primeng/accordion';
import { CardModule } from 'primeng/card';
import { PanelModule } from 'primeng/panel';
import { FileUploadModule } from 'primeng/fileupload';
import {ToastModule} from 'primeng/toast';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';

FullCalendarModule.registerPlugins([
	dayGridPlugin,
	timeGridPlugin,
	interactionPlugin,
]);

@NgModule({
	declarations: [],
	imports: [
		DropdownModule,
		CalendarModule,
		AutoCompleteModule,
		CheckboxModule,
		ChipsModule,
		ColorPickerModule,
		InputMaskModule,
		InputSwitchModule,
		InputTextModule,
		InputTextareaModule,
		InputNumberModule,
		KeyFilterModule,
		ListboxModule,
		MultiSelectModule,
		PasswordModule,
		RadioButtonModule,
		RatingModule,
		SliderModule,
		SelectButtonModule,
		ToggleButtonModule,
		TriStateCheckboxModule,
		ButtonModule,
		SplitButtonModule,
		TableModule,
		AccordionModule,
		CardModule,
		PanelModule,
		FileUploadModule,
    ToastModule,
		CommonModule,
		FormsModule,
		FullCalendarModule,
		DialogModule,
		InputTextModule,
		CalendarModule,
		CheckboxModule,
		ButtonModule,
		TabViewModule,
		MessagesModule,
		MessageModule,
	],
	exports: [
		DropdownModule,
		CalendarModule,
		AutoCompleteModule,
		CheckboxModule,
		ChipsModule,
		ColorPickerModule,
		InputMaskModule,
		InputSwitchModule,
		InputTextModule,
		InputTextareaModule,
		InputNumberModule,
		KeyFilterModule,
		ListboxModule,
		MultiSelectModule,
		PasswordModule,
		RadioButtonModule,
		RatingModule,
		SliderModule,
		SelectButtonModule,
		ToggleButtonModule,
		TriStateCheckboxModule,
		ButtonModule,
		SplitButtonModule,
		TableModule,
		AccordionModule,
		CardModule,
		PanelModule,
		FileUploadModule,
    	ToastModule,
		CommonModule,
		FormsModule,
		FullCalendarModule,
		DialogModule,
		InputTextModule,
		CalendarModule,
		CheckboxModule,
		ButtonModule,
		TabViewModule,
		MessagesModule,
		MessageModule,
	],
})
export class NgPrimeModule {}
